<template>
  <div>
    <vue-element-loading
      :active="getLoader"
      spinner="bar-fade-scale"
      text="This may take a couple of minutes. Thank you for your patience.."
    />
    <label style="margin: 0;">
      <h6>Pre-Allocation Sales & Inventory Reports</h6>
    </label>
    <hr style="margin: 0; border-color: #aaa2a2;" size="30" />
    <b-row>
      <b-col cols="2" class="mb-2 mt-1">
        <b-button size="md" class="btn-create" @click="onDowloadReportsTemplate">
          Download Template
          <i class="fa fa-download" style="padding-left:0.25rem;"></i>
        </b-button>
      </b-col>
      <b-col cols="2" class="mb-2 mt-1">
        <b-button size="md" class="btn-create" @click="onUploadReportsTemplate">
          Upload
          <i class="fa fa-upload" style="padding-left:0.25rem;"></i>
        </b-button>
      </b-col>
      <b-col cols="8" class="mb-2 mt-1" align="end">
        <b-button size="md" class="btn-create" @click="getCreationPage" id="createAllocationButton">
          Create New Allocation
          <i class="fa fa-plus-square" style="padding-left:0.25rem;"></i>
        </b-button>
      </b-col>
    </b-row>
    
    <!-- <div v-if="showDismissibleAlert" style="color: red; text-align: center;">
      {{errorMesg}}
    </div>-->
    <!-- <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>!! {{errorMesg}}</b-alert> -->
    <b-modal
      id="dataUpdateInfo"
      v-model="showInfoModal"
      size="md"
      centered
      title="Last Data Updated"
      hide-footer
    >
      <template slot="title">
        <b-button
          class="close"
          aria-label="Close"
          @click="onClosePopOver"
        >
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
      </template>
      <!-- <div v-on-click-outside="close"> -->
      <div v-if="tableData">
        <table class="table-info">
          <tr>
            <th>Table Name</th>
            <th>Update On</th>
          </tr>
          <tr v-for="(data, i) in tableData" :key="i">
            <td>{{data.tableName}}</td>
            <td>{{data.updatedOn}}</td>
          </tr>
        </table>
      </div>
    </b-modal>
    <b-card>
      <div slot="header">
        <strong>{{getDashboardTable.length}} Allocations</strong>
      </div>
      <data-table
        @selectedItems="v => seletedItems = v"
        @cellClicked="getCompletePage"
        :colDefs="siteSelectionColDef"
        :data="storeData"
        :autoResize="true"
        :deleteActionFlag="true"
        :ifDisplayCount="false"
        pivotPanelShow="always"
        :sideBar="true"
      >
        <template slot-scope="props">
          Actions:
          <span>
            <i
              v-if="!seletedItems.length"
              v-b-tooltip.hover
              title="Please select an Allocation to use this option"
              class="far fa-trash-alt cursor-not-allowed"
              style="margin-right:1rem; color: #c71585;"
            ></i>
            <i
              v-else
              class="far fa-trash-alt cursor-pointer"
              style="margin-right:1rem; color: #c71585;"
              @click="props.onDelete"
            ></i>
            <i
              v-b-tooltip.hover
              title="Data Update Info"
              class="fas fa-info-circle cursor-pointer showInfoIcon"
              style="margin-right:1rem;"
              @click="showInfoModal=true"
            ></i>
          </span>
          <!-- <i  class="far fa-trash-alt cursor-pointer ml-2" style="color: #c71585"></i> -->
        </template>
      </data-table>
    </b-card>
  </div>
</template>

<script>
import { concat } from "lodash";
import DataTable from "@/components/DataTable";
import siteSelectionColDef from "@/constants/tableDefinitions/dashboard.js";
import { AgGridVue } from "ag-grid-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default {
  components: {
    DataTable
  },
  data() {
    return {
      tableData: null,
      showInfoModal: false,
      fileSelector: null,
      showDismissibleAlert: false,
      selectedFile: null,
      errorMesg: "",
      flagDownload: false,
      stepStage: "",
      seletedItems: [],
      storeData: [],
      siteSelectionColDef
    };
  },
  watch: {
    getDashboardTable(val) {
      this.tableData = JSON.parse(window.localStorage.getItem('dataUpdateInfo'))
      this.storeData = val;
    },
    flagDownload(val) {
      if (val) {
        document.body.removeChild(this.fileSelector);
        this.flagDownload = false;
        // this.fileSelector = null
      }
    }
    // onChangeFlag(val) {
    //   if (val) {
    //     document.body.removeChild(this.fileSelector);
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      getLoaderText: "CreateAllocation/getLoaderText",
      getLoader: "Dashboard/getLoader",
      getDashboardTable: "Dashboard/getDashboardTable"
    })
  },
  methods: {
    ...mapActions({
      allocationByAllocationId: "CreateAllocation/allocationByAllocationId",
      deleteAllocation: "CreateAllocation/deleteAllocation",
      dashboardTable: "Dashboard/dashboardTable",
      UploadPreAllocationReportInput: "Dashboard/UploadPreAllocationReportInput"
    }),
    ...mapMutations({
      setLoader: "Dashboard/setLoader",
      setDashboardTable: "CreateAllocation/setDashboardTable"
    }),
    onClosePopOver() {
      this.popoverShow = false;
    },
    onDowloadReportsTemplate() {
      var a = document.createElement("A");
      a.href = "./Report-Articles-Input.xlsx";
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      //this.$gtag.config({ user_id: 'Hariprasad_new' })
      this.$gtag.event('Clicked_PreAllocationReportDownloadButton', {
        'event_category': 'Dashboard Screen',
        'event_label': 'Click on Download Template Button',
      })
    },
    onUploadReportsTemplate() {
      // this.onChangeFlag = true
      if (this.fileSelector) {
        this.fileSelector.removeAttribute("type");
        this.fileSelector.removeAttribute("id");
      }
      this.fileSelector = document.createElement("input");
      this.fileSelector.setAttribute("type", "file");
      this.fileSelector.setAttribute("id", "uploadTemplate1");
      var selectDialogueLink = document.createElement("a");
      selectDialogueLink.setAttribute("href", "");
      selectDialogueLink.innerText = "Select File";
      document.body.appendChild(this.fileSelector);
      this.fileSelector.click();
      this.fileSelector.onchange = () => {
        // this.onChangeFlag = false
        this.selectedFile = document.getElementById("uploadTemplate1").files[0];
        this.UploadPreAllocationReportInput({
          file: this.selectedFile
        })
          .then(res => {
            let a1 = document.createElement("A");
            (a1.href = ""), (a1.href = res.data.url);
            a1.setAttribute("target", "_blank");
            document.body.appendChild(a1);
            a1.click();
            document.body.removeChild(a1);
            this.flagDownload = true;
          })
          .catch(err => {
            Swal.fire({
              title: 'Error!',
              text: err.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            this.flagDownload = true;
            // this.showDismissibleAlert = true;
            // this.errorMesg = err.message;
          });
        this.selectedFile = null;
      };
      // if(!onChangeFlag) {
      //   this.flagDownload = true;
      // }
      this.$gtag.event('Clicked_PreAllocationReportUploadButton', {
        'event_category': 'Dashboard Screen',
        'event_label': 'Click on Upload Template Button',
      })
    },
    // onDeleteAllocation(data) {
    //   this.$dialog.confirm('Are you sure you want to delete the allocation project(s)?').then(() => {
    //     let arr = []
    //     data.removedData.forEach(obj => {
    //       arr.push(obj.allocationid)
    //     })
    //     this.deleteAllocation({allocationIDs: arr}).then(resp => {
    //     })
    //   }).catch(() => {
    //     this.storeData = concat(data.existingData, data.removedData)
    //   })
    // },
    getCreationPage() {
       this.$gtag.event('Clicked_CreateNewAllocationButton', {
        'event_category': 'Dashboard Screen',
        'event_label': 'Click Create New Allocation Button',
      })
      this.$router.push("/createAllocation");
    },
    getCompletePage(val) {
      if (val.colDef.headerName == "Allocation Name") {
        if (val.data.allocationstatus == "SUBMITTED") {
          this.setLoader(true);
          this.allocationByAllocationId({
            allocationID: val.data.allocationid
          }).then(resp => {
            this.$router.push("/completedAllocation");
            this.setLoader(false);
          });
        } else {
          this.setLoader(true);
          this.$router.push(`/createAllocation/${val.data.allocationid}`);
          this.setLoader(false);
        }
      }
    }
  },
  created() {
    this.dashboardTable();
  }
};
</script>

<style scoped>
.btn-create {
  background-color: rgb(115, 189, 0);
  border-color: rgb(115, 189, 0);
  color: white;
}
.popover {
  /* overflow: auto !important; */
}
.table-info {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  background-color: transparent;
  width: 90%;
  margin: 0.5rem auto;
}
.table-info tr {
  border: 1px solid grey;
}
.table-info tr th {
  padding: 0.4rem;
  background-color: rgb(228, 225, 225);
}
.table-info tr td {
  padding: 0.4rem;
}
.showInfoIcon {
  font-weight: 900;
  color: royalblue;
  padding-left: 5px;
}
</style>
<style>
.close:hover {
    color: #fff;
    text-decoration: none;
}
.close {
    color: white;
    text-decoration: none;
}
#dataUpdateInfo___BV_modal_header_ {
  color: #ffffff !important;
  background-color: #000000;
}
.card-header {
  padding: 0.75rem 1.25rem;
  color: #ffffff !important;
  margin-bottom: 0;
  background-color: #000000 !important;
  border-bottom: 1px solid #c8ced3;
}
.dg-main-content {
  max-width: 440px !important;
}
</style>

