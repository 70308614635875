<template>
  <div>
    <dashboard></dashboard>
    <!-- <offline-page :show="show"></offline-page> -->
  </div>
</template>
<script>
import Dashboard from "@/components/Dashboard/AllocationDashboard";
import OfflinePage from "@/components/offlinePage";
import Vue from "vue";
export default {
  components: {
    Dashboard,
    OfflinePage
  },
  data() {
    return {
      show: false
    };
  },
  beforeMount() {
    if (!window.navigator.onLine) {
      this.show = true;
    }
  }
};
</script>
<style>
</style>
