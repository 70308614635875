import { dateFormatter } from '@/helpers/formatters'

export default {
  defaultColDef: 
  {
    animateRows: true,
    sortable: true,
    filter: true,
    unSortIcon: true
  },
  columnDefs: [
    {
      resizable: true,
      headerName: "Allocation Name",
      width: 200,
      field: "name",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: "left",
      filter: "agTextColumnFilter",
      cellStyle: { "text-decoration": "underline", "color": "blue", "cursor": "pointer" }
    },
    {
      resizable: true,
      headerName: "Creation Date",
      width: 150,
      valueFormatter: dateFormatter,
      field: "createdat",
      filter: "agTextColumnFilter"
    },
    {
      resizable: true,
      headerName: "Last Modified Date",
      width: 200,
      valueFormatter: dateFormatter,
      field: "updatedat",
      filter: "agTextColumnFilter",
      sort: "desc"
    },
    {
      resizable: true,
      headerName: "Push",
      width: 100,
      field: "push",
      filter: "agTextColumnFilter"
    },
    {
      resizable: true,
      headerName: "Allocation Status",
      width: 180,
      field: "allocationstatus",
      filter: "agTextColumnFilter"
    },
    {
      resizable: true,
      headerName: "SAP Upload",
      width: 140,
      field: "sapReportUploaded",
      cellRenderer: "SapDownloadFlag"
    },
    {
      resizable: true,
      headerName: "Comments",
      field: "comments",
      width: 200,
      filter: "agTextColumnFilter",
      tooltipField: 'comments'
    }
  ]
}
